<template>
  <section class="flex flex-col px-4 xl:py-16">
    <h1 class="text-8xl">Hi!</h1>
    <h2 class="text-4xl lg:text-5xl mt-10 mb-16">Welcome to my little corner on the internet 😊</h2>
    <p class="text-3xl mb-10">
      My name is Liel, and I'm a nerd who likes to build stuff.
      Whether it is tech or music/sound, my mission is creating and tinkering with stuff, for fun, and sometimes profit.
    </p>
    <p class="text-2xl mb-5">
      There is not much here at the moment, but feel free to connect on social media!
    </p>
    <div class="flex flex-row">
      <a v-for="(item, index) in socialMedia"
         :key="index"
         role="button"
         :href="item.link"
         target="_blank"
         class="no-underline p-4 rounded-2xl bg-orange-500 text-lg hover:bg-orange-600 transition-colors mr-4">
        <font-awesome-icon :icon="['fab', ...item.icon]"></font-awesome-icon>
        {{ item.label }}
      </a>
    </div>
  </section>
</template>

<script setup>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const socialMedia = ref([
  {
    icon: ['linkedin'],
    label: 'LinkedIn',
    link: 'https://www.linkedin.com/in/lielfr/'
  },
  {
    icon: ['instagram'],
    label: 'Instagram',
    link: 'https://www.instagram.com/liel.fr/'
  },
  {
    icon: ['stack-overflow'],
    label: 'StackOverflow',
    link: 'https://stackoverflow.com/users/11143111/liel-fridman'
  },
  {
    icon: ['github'],
    label: 'GitHub',
    link: 'https://github.com/lielfr'
  }
]);
</script>

<style scoped>

</style>